.fancy {
    font-family: "Square Peg", cursive;
    font-size: 3.1rem;
    line-height: 0.9em;
    margin-top: 40px;
}

.main {
    height: 90vh;
    margin: 0;
    padding: 0;
    text-align: center;
}

.button {
    font-size: 20px;
    margin: 10px !important;
}

.wrapper {
    margin-top: 34vh;
    margin-left: auto;
    margin-right: auto;
    height: 200px;
    width: 300px;
    background-color: #ec5c9d;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 0;
}

.lid {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-right: 150px solid transparent;
    border-bottom: 100px solid transparent;
    border-left: 150px solid transparent;
    transform-origin: top;
    transition: transform 0.25s linear;
}

.lid.one {
    border-top: 100px solid #e54b90;
    transform: rotateX(0deg);
    z-index: 3;
    transition-delay: 0.75s;
}

.lid.two {
    border-top: 100px solid #e54b90;
    transform: rotateX(90deg);
    z-index: 1;
    transition-delay: 0.5s;
}

.envelope {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-top: 100px solid transparent;
    border-right: 150px solid #f592b1;
    border-bottom: 100px solid #f69ab3;
    border-left: 150px solid #ff8dae;
    z-index: 3;
}

.letter {
    position: absolute;
    top: 0;
    width: 90%;
    height: 100%;
    background-color: #fdd7ba;
    border-radius: 15px;
    z-index: 2;
    transition: 0.6s;
    text-align: center;
}

.letter button {
    font-size: 30px;
    color: #3B4049;
    position: relative;
    margin-top: 20px;
    z-index: 10;
    border-radius: 10px;
}

.wrapper:hover .lid.one {
    transform: rotateX(90deg);
    transition-delay: 0s;
}

.wrapper:hover .lid.two {
    transform: rotateX(180deg);
    transition-delay: 0.25s;
}

.wrapper:hover .letter {
    transform: translateY(-50px);
    transition-delay: 0.5s;
}

.wrapper:hover .letter button {
    transform: translateY(-10px);
    transition-delay: 0.1s;
}

.wrapper button:hover {
    border-color: #f817a4;
    background-color: #ee9d40;
}

.container {
    position: absolute;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    height: 400px;
    overflow: hidden;
    z-index: -100;
}

.tulip {
    width: 10px;
    height: 200px;
    margin: 30px 60px;
    float: left;
}

.mainTulip {
    transform: scale(.9);
    left: 100px;
    top: 180px;
}

.leftBabyTulip {
    transform: scale(.4);
    left: -13px;
    top: 210px;
}

.rightStemLeaf {
    top: 105px;
    transform: rotate(-32deg);
    left: -15px;
}

.leftStemLeaf {
    transform: scale(0.7) rotate(90deg);
    left: -54px;
}


.rightBabyTulip {
    transform: scale(.5);
    left: 240px;
    top: 190px;
}

.tulipHead {
    transform: rotate(23deg);
    left: -80px;
}

.leftStemLeaf {
    visibility: hidden;
}

.rightStemLeaf {
    top: 70px;
    transform: rotate(-32deg);
    left: -15px;
}

.tulip,
.stem,
.stemLeaf,
.tulipHead,
.tulipLeaf,
.tulipHair,
.tulipEyes,
.tulipSmile,
.tulipBlush {
    position: absolute;
}

.stem {
    height: 200px;
    width: 10px;
    border-radius: 50px;
    background-color: #25af59;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 0;
}

.stemLeaf {
    background-color: #139a3e;
    width: 60px;
    height: 60px;
}

.leaf {
    border-radius: 90% 0 90% 0;
}

.rightStemLeaf {
    top: 60%;
    width: 100px;
    transform: rotate(-10deg);
    left: -1px;
}

.leftStemLeaf {
    top: 20%;
    width: 80px;
    transform: rotate(90deg);
    left: -63px;
    z-index: -2;
}

.tulipHead {
    top: -159px;
    left: -102px;
    background-color: #f3a69e;
    width: 200px;
    height: 165px;
    border-radius: 0 0 50% 50%;
    z-index: 0;
    transform: rotate(-11deg);
}

.tulipLeaf {
    background-color: #ff8081;
    width: 40px;
    height: 40px;
    z-index: -1;
}

.rightTulipLeaf {
    transform: rotate(90deg);
    left: 4px;
}

.leftTulipLeaf {
    left: -33px;
}

.tulipHair {
    width: 0;
    height: 0;
    top: -60px;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 60px solid #f3a69e;
}

.lightTulip {
    border-bottom: 60px solid #f3a69e;
}

.darkTulip {
    border-bottom: 60px solid #ff8081;
    border-bottom: 32px solid #ff8081;
    top: -32px;
    z-index: -1;
}

.darkTulip-1 {
    left: 30px;
}

.darkTulip-2 {
    left: 111px;
}

.lightTulip-1 {
    transform: rotate(-27deg);
    top: -43px;
    left: -17px;
}

.lightTulip-2 {
    left: 70px;
}

.lightTulip-3 {
    transform: rotate(27deg);
    top: -43px;
    left: 156px;
}

.tulipEyes {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #222;
    top: 55px;
}

.leftEye {
    left: 69px;
}

.rightEye {
    left: 119px;
}

.tulipSmile {
    width: 37px;
    height: 18px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border: 5px solid #222;
    transform: rotate(180deg);
    top: 73px;
    left: 81px;
    border-bottom: 0;
}

.tulipBlush {
    border-radius: 50%;
    background-color: #ff8081;
    width: 30px;
    height: 30px;
}

.leftBlush {
    top: 66px;
    left: 44px;
}

.rightBlush {
    top: 66px;
    left: 123px;
}